import { useState } from 'react'

export const Location = (props) => {
  return (
    <div>
      <div id='location'>
        <div className='content'>
          <img id='map' src="img/big_logo.png"></img>
          <div className='info'>
            <div className='items'>
              <div className='address'>
                <p className="address_text">Contact Us Today:</p>
              </div>
              <div className='email'>
                <img className="email_icon" src="img/email.svg"></img>
                <a href="mailto:info@perseus.ltd?subject=Contact to perseus.ltd"><p>info@perseus.ltd</p></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
