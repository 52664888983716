import { useState, useEffect } from "react";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Pricing } from "./components/pricing";
import { Testimonials } from "./components/testimonials";
import { Location } from "./components/location";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { AuditedModal } from "./components/audited_modal";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <Provider template={AlertTemplate} {...options}>
      <Header data={landingPageData.Header} openModal={openModal} />
      <Features data={landingPageData.Features} />
      <About openModal={openModal}/>
      <Testimonials/>
      <Pricing/>
      <Contact/>
      <Location/>
      <AuditedModal isOpen={modalIsOpen} closeModal={closeModal}/>
    </Provider>
  );
};

export default App;
