export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Featured Audits</h2>
          <p>Leading project that we have audited. $10 Billion locked.</p>
        </div>
        <div className='feature-box'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='feature-item'>
                  <p>{d.title}</p>
                  {d.link ? <a href={d.link} target="_blank"><img src={d.img}></img></a> : <img src={d.img}></img> }
                </div>
              ))
            : 'Loading...'}
        </div>
        <a href='#features' className='btn btn-blue btn-more'>More</a>
      </div>
    </div>
  )
}
