export const About = (props) => {
  const { openModal } = props;

  return (
    <div id="about">
      <div className="container">
        <div className='section-title'>
          <h2>The Process About Our Work</h2>
          <p>We ensure your smart contract goes through all the stages from manual code review till automated testing before we generate the initial audit report. Once your team updates the code, we do a thorough scrutiny of the smart contract to provide you with the Final Audit report.<br/>To find out more about our audit process please refer to any of our featured audit reports. </p>
        </div>
        <a className='btn btn-blue btn-audit-about' onClick={openModal}>Get Audited</a>
        <img src="img/code_box.png" className="code-box"/>
        <div className="description">
          <p style={{paddingTop: "36px"}}>Detect Reentrency vulnerabilities before your project gets exploited and have peace of mind.</p>
        </div>
      </div>
    </div>
  );
};
