import { useState } from "react"
import { useAlert } from "react-alert";

export const Navigation = (props) => {
  const [email, setEmail] = useState('');
  const alert = useAlert();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleSubscribe = async () => {
    try {
      const response = await fetch(`https://perseus.ltd/api/v1/subscribe?email=${email}`);
      const result = await response.json();
      if (result.data && result.data == 'done') {
        alert.success("Success!");
        return;
      }
    } catch (err) {
      console.log(err);
    }
    alert.error("Error!");
  }

  return (
    <nav id='menu' className='navbar navbar-default'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
          </button>
          <img src="img/logo.png" className='logo' />
          <a className='navbar-brand page-scroll' href='#page-top'>
            Perseus.ltd
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          { 
            window.innerWidth > 520 && <>
              <a className='btn btn-subscribe' onClick={handleSubscribe}>Subscribe</a>
              <input type="email" className='input-email' placeholder="Your Email" onChange={handleEmailChange}/>
            </>
          }
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#home' className='page-scroll'>
                Home
              </a>
            </li>
            <li>
              <a href='#pricing' className='page-scroll'>
                Pricing
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
            <li class="dropdown">
              <a className='dropdown-toggle' data-toggle="dropdown" data-hover="dropdown" data-delay="0" data-close-others="false">
                Product
              </a>
              <ul class="dropdown-menu">
                <li><a tabindex="-1" href="/audits/hnc.pdf" target="_blank">HNC Coin</a></li>
                <li><a tabindex="-1" href="https://coinbae.org" target="_blank">Market Data</a></li>
              </ul>
            </li>
            { 
              window.innerWidth <= 520 && <>
                <input type="email" className='input-email' placeholder="Your Email" onChange={handleEmailChange}/>
                <a className='btn btn-subscribe' onClick={handleSubscribe}>Subscribe</a>
              </>
            }
          </ul>
        </div>
      </div>
    </nav>
  )
}
