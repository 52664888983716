import React, { useRef, useState } from 'react';
import { useAlert } from "react-alert";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: "#252B42",
  },
};

const options = [
  { value: 'ETH', label: 'ETH' },
  { value: 'TRX', label: 'TRX' },
  { value: 'BSC', label: 'BSC' },
  { value: 'HECO', label: 'HECO' },
  { value: 'OKEX', label: 'OKEX' },
  { value: 'Polygon', label: 'Polygon' },
  { value: 'Other', label: 'Other' },
];
const defaultOption = options[0];

export const AuditedModal = (props) => {
  const subtitle = useRef();
  const alert = useAlert();
  const { isOpen, closeModal } = props;
  const [chain, setChain] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const afterOpenModal = () => {
    subtitle.current.style.color = 'white';
    setError('');
    setChain('');
    setAddress('');
    setEmail('');
  }
  const onChainChange = (e) => setChain(e.value);
  const onAddressChange = (e) => setAddress(e.target.value);
  const onEmailChange = (e) => setEmail(e.target.value);
  const onSubmit = async () => {
    if (!chain || !address || !email) {
      setError("You have to input all values!");
      return;
    }

    try {
      const response = await fetch(`https://perseus.ltd/api/v1/audit?email=${email}&contract=${address}&network=${chain.toUpperCase()}`);
      const result = await response.json();
      if (result.data && result.data == 'done') {
        alert.success("Success!");
        closeModal();
        return;
      }
    } catch (err) {
      console.log(err);
    }

    alert.error("Error!");
    closeModal();
  }

  return (
    <div id="audit-modal">
      <Modal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Request Audit"
        ariaHideApp={false}
      >
        <h2 ref={subtitle}>Request Audit</h2>
        <div className="audit-form">
          <div className="select-chain">
            <label>Select Chain</label>
            <Dropdown menuClassName="chain-dropdown" options={options} onChange={onChainChange} value={defaultOption} placeholder="Select an option" />
          </div>
          <div className="contract-address">
            <label>Contract address</label>
            <input name="contract-address" placeholder="0x" onChange={onAddressChange}/>
          </div>
          <div className="email-address">
            <label>Email address</label>
            <input type="email" name="email-address" onChange={onEmailChange}/>
          </div>
          <p className="error">{error}</p>
          <div className="form-action">
            <button onClick={onSubmit}>Submit</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
