import { useState } from 'react'
import { useAlert } from "react-alert";

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const alert = useAlert();

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`https://perseus.ltd/api/v1/contact?email=${email}&name=${name}&message=${message}`);
      const result = await response.json();
      if (result.data && result.data == 'done') {
        alert.success("Success!");
        return;
      }
    } catch (err) {
      console.log(err);
    }
    alert.error("Error!");
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='section-title'>
            <h2>Contact Us</h2>
            <p>Reach out to us for queries.</p>
          </div>
          <div className='content'>
            <form name='sentMessage' validate onSubmit={handleSubmit}>
              <p>Contact Us</p>
              <input type="text" name="name" className='input-name' placeholder="Your Name" onChange={handleChange}/>
              <input type="email" name="email" className='input-email' placeholder="Your Email" onChange={handleChange}/>
              <textarea
                name='message'
                id='message'
                rows='4'
                placeholder='Your Message'
                required
                onChange={handleChange}
              ></textarea>
              <button type='submit' className='btn btn-send'>
                Send
              </button>
            </form>
            <div className='info'>
              <div className='items'>
                <div className='email'>
                  <img className="email_icon" src="img/email.svg"></img>
                  <a href="mailto:info@perseus.ltd?subject=Contact to perseus.ltd"><p>info@perseus.ltd</p></a>
                </div>
                <div className='socials'>
                  <a href="https://twitter.com/Perseusaudit" target="_blank"><img src="img/twitter.svg"></img></a>
                  <a href="https://www.linkedin.com/company/perseusltd" target="_blank"><img src="img/linkedin.svg"></img></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
