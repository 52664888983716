export const Pricing = (props) => {
  return (
    <div id="pricing">
      <div className="container">
        <div className='section-title'>
          <h2>Pricing</h2>
          <p>We offer flexible pricing structures for various projects. Contact us for a detailed quote.</p>
        </div>
        <div className="price-box">
          <div className="third-price">
            <p className="title">Smart Contract Review <br/>And fixing.</p>
            <p className="description">Contact us for detailed pricing</p>
            <p className="price">SQ<span className="currency">$</span></p>
            <a href='#contact' className='btn btn-blue btn-order'>Order Now</a>
          </div>
        </div>
      </div>
    </div>
  );
};
