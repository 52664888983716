export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className='section-title'>
          <h2>Testimonials</h2>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-item">
            <img src="img/testimonial.png" className="img-testimonial"/>
            <p>Dracula Protocol<br/>The Perseus team not only provided a transparent and thorough audit for our contracts, but did so in a timely and professional manner. We plan on using them consistently for our developments in order to prioritize the security of our user’s funds.<br/>Dio - Founder</p>
          </div>
          <div className="testimonial-item">
            <img src="img/HNC_Coin.png" className="img-testimonial"/>
            <p>HNC Coin<br/>As cyberattacks become more frequent and sophisticated, a proactive approach to identifying vulnerabilities can reduce the likelihood and severity of an attack. The Perseus team are experts in this! HNC Coin received an excellent security audit In a very short time. Nikos Kakaniaris - Chief Operating Officer</p>
          </div>
          <div className='testimonial-item'>
            <img src="img/tapcoins.jpeg" className="img-testimonial"></img>
            <p>As new users and blockchainers come into the project, they can have confidence that our contracts are secure and safe! - Haniff Co Founder Gamexchange</p>
          </div>
          
        </div>
        <a href='#testimonials' className='btn btn-blue btn-testimonial'>More Testimonials</a>
      </div>
    </div>
  );
};
